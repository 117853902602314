// import localStorage from 'store';
import {get} from "lodash";
import {getState} from "./../state/configureStore.js";

const AUTH_TOKEN_STORE_KEY = "auth-token";
const USER_INFORMATION = "user_information";
const AUTH_SOCIAL_STORE_KEY = "social-id";
const SSO_USER_INFO = "sso_user";
const PACKAGE_INFO_MODAL_CLOSING_DATE = "package_info_modal_closing_date";
const PACKAGE_INFO_BANNER_CLOSING_DATE = "package_info_banner_closing_date";
const CURRENT_CONNECTED_SHOP = "current_connected_shop";
const SELECTED_CHANNEL = "selected_channel";
const FBE_REDIRECT_URI = "fbe_redirect_uri";

const CURRENT_SHOP = "current_shop";

const SSO_ACCESS_TOKEN = "sso_access_token";

export const removeAuthToken = () => {
    return localStorage.removeItem(AUTH_TOKEN_STORE_KEY)
}

export const setAuthToken = (token) => {
    return localStorage.setItem(AUTH_TOKEN_STORE_KEY, token)
}

export const getAuthToken = () => {
    return localStorage.getItem(AUTH_TOKEN_STORE_KEY)
}


export const removeUserInfo = () => {
    return localStorage.removeItem(USER_INFORMATION)
}

export const setUserInfo = (user) => {
    return localStorage.setItem(USER_INFORMATION, JSON.stringify(user))
}

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(USER_INFORMATION))
}


export const hasAuthToken = () => {
    return !!getAuthToken()
}


export const setCurrentShop = (shop) => {
    return localStorage.setItem(CURRENT_SHOP, shop);
};
export const getCurrentShop = () => {
    return localStorage.getItem(CURRENT_SHOP);
};
export const removeCurrentShop = () => {
    return localStorage.removeItem(CURRENT_SHOP);
};
export const getSSOUser = () => {
    return localStorage.setItem(SSO_USER_INFO);
};
export const setSocialId = (socialId) => {
    return localStorage.setItem(AUTH_SOCIAL_STORE_KEY, socialId);
};

export const getSSOAcessToken = () => {
    return localStorage.getItem(SSO_ACCESS_TOKEN);
};

export const setSSOAcessToken = (token) => {
    return localStorage.setItem(SSO_ACCESS_TOKEN, token);
};

export const getSocialId = () => {
    return localStorage.getItem(AUTH_SOCIAL_STORE_KEY);
};

export const setPackageInfoModalClosingDate = (date) => {
    return localStorage.setItem(PACKAGE_INFO_MODAL_CLOSING_DATE, date);
};

export const getPackageInfoModalClosingDate = () => {
    return localStorage.getItem(PACKAGE_INFO_MODAL_CLOSING_DATE);
};

export const setPackageInfoBannerClosingDate = (date) => {
    return localStorage.setItem(PACKAGE_INFO_BANNER_CLOSING_DATE, date);
};

export const getPackageInfoBannerClosingDate = () => {
    return localStorage.getItem(PACKAGE_INFO_BANNER_CLOSING_DATE);
};

export const setCurrentConnectedShop = (shop) => {
    return localStorage.setItem(CURRENT_CONNECTED_SHOP, shop);
};

export const getCurrentConnectedShop = () => {
    let current = localStorage.getItem(CURRENT_CONNECTED_SHOP);
    if (current) {
        return current;
    }

    const {auth} = getState();
    current = get(auth, "userShop");
    localStorage.setItem(CURRENT_CONNECTED_SHOP, current);
    return current;
};

export const setSelectedChannel = (channel) => {
    return localStorage.setItem(SELECTED_CHANNEL, channel);
};

export const getSelectedChannel = () => {
    return localStorage.getItem(SELECTED_CHANNEL);
};

export const setFBERedirectUri = (uri) => {
    return localStorage.setItem(FBE_REDIRECT_URI, uri);
};

export const getFBERedirectUri = () => {
    return localStorage.getItem(FBE_REDIRECT_URI);
};
