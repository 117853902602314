import {createReducer} from "redux-create-reducer";
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const REQUEST_PRODUCTS_SUCCESS = "REQUEST_PRODUCTS_SUCCESS";
export const REQUEST_PRODUCTS_FAILURE = "REQUEST_PRODUCTS_FAILURE";

export const IMPORT_NFTS = "IMPORT_NFTS";
export const IMPORT_NFTS_SUCCESS = "IMPORT_NFTS_SUCCESS";
export const IMPORT_NFTS_FAILURE = "IMPORT_NFTS_FAILURE";

export const GET_NFTS = "GET_NFTS";
export const GET_NFTS_SUCCESS = "GET_NFTS_SUCCESS";
export const GET_NFTS_FAILURE = "GET_NFTS_FAILURE";

export const DELETE_NFT_SAGA = "DELETE_NFT_SAGA";
export const DELETE_NFT = "DELETE_NFT";
export const DELETE_NFT_SUCCESS = "DELETE_NFT_SUCCESS";
export const DELETE_NFT_FAILURE = "DELETE_NFT_FAILURE";

export const DELETE_NFT_MULTIPLE_SAGA = "DELETE_NFT_MULTIPLE_SAGA";
export const DELETE_NFT_MULTIPLE = "DELETE_NFT_MULTIPLE";
export const DELETE_NFT_SUCCESS_MULTIPLE = "DELETE_NFT_SUCCESS_MULTIPLE";
export const DELETE_NFT_FAILURE_MULTIPLE = "DELETE_NFT_FAILURE_MULTIPLE";

export const ACTION_CHANGE_PRODUCT = "ACTION_CHANGE_PRODUCT";
export const UPDATE_PRODUCT_DATA = "UPDATE_PRODUCT_DATA";
export const CHANGE_FILTER = "CHANGE_FILTER";

const defaultState = {
  nftFilter: {
    limit: 10,
    page: 1,
    network: '',
    status: ''
  },


  nfts: [],
  totalNft: 0,
  fetchingNfts: false,
  failedFetchingNfts: false,
};

const reducer = createReducer(defaultState, {

  [GET_NFTS]: (state) => ({
    ...state,
    fetchingNfts: true,
    failedFetchingNfts: false,
  }),
  [GET_NFTS_SUCCESS]: (state, action) => ({
    ...state,
    nfts: action.payload?.data?.items.map((item) => {
      return {
        ...item,
        key: item.id,
      }
    }),
    totalNft: action.payload?.data.meta.totalItems,
    fetchingNfts: false,
    failedFetchingNfts: false,
  }),
  [GET_NFTS_FAILURE]: (state) => ({
    ...state,
    fetchingNfts: false,
    failedFetchingNfts: true,
  }),


  [UPDATE_PRODUCT_DATA]: (state, action) => ({
    ...state,
    products: get(action, "payload.products", []),
    editedProducts: get(action, "payload.editedProducts", []),
  }),
  [CHANGE_FILTER]: (state, action) => ({
    ...state,
    nftFilter: action.payload,
  }),
});

export default reducer;
export const namespace = "home";

export const actionChangeProduct = (newProduct) => ({
  type: ACTION_CHANGE_PRODUCT,
  payload: newProduct,
});

export const actionUpdateProductData = (payload) => ({
  type: UPDATE_PRODUCT_DATA,
  payload,
});

export const changeFilter = (payload) => ({
  type: CHANGE_FILTER,
  payload,
});

export const deleteNftSinger = (payload) => ({
  type: DELETE_NFT_SAGA,
  payload,
});

export const deleteNftMultiple = (payload) => ({
  type: DELETE_NFT_MULTIPLE_SAGA,
  payload,
});

export const getProducts = (filter) => async (dispatch, getState) => {
  return await callAPI({
    method: "get",
    apiPath: "/products",
    actionTypes: [
      REQUEST_PRODUCTS,
      REQUEST_PRODUCTS_SUCCESS,
      REQUEST_PRODUCTS_FAILURE,
    ],
    variables: {
      ...filter,
    },
    dispatch,
    getState,
  });
};

export const importNFTs = (data) => async (dispatch, getState) => {
  let formData = new FormData();
  formData.append("file", data);
  return callAPI({
    method: "post",
    apiPath: "/nft/import",
    actionTypes: [IMPORT_NFTS, IMPORT_NFTS_SUCCESS, IMPORT_NFTS_FAILURE],
    variables: formData,
    dispatch,
    getState,
    headers: {"Content-Type": "multipart/form-data"},
  });
};

export const getNFTs =
  ({page, limit, network, status}) =>
    async (dispatch, getState) => {
      let variables = {page, limit}
      if (!!network) {
        variables = {...variables, network}
      }
      if (!!status) {
        variables = {...variables, status}
      }
      return callAPI({
        method: "get",
        apiPath: "/nft",
        actionTypes: [GET_NFTS, GET_NFTS_SUCCESS, GET_NFTS_FAILURE],
        variables,
        dispatch,
        getState,
      });
    };

export const callAPIDeleteNFT = (id) => async (dispatch, getState) => {
  const apiPath = `/nft/${id}`
  return callAPI({
    method: 'delete',
    apiPath: apiPath,
    actionTypes: [
      DELETE_NFT,
      DELETE_NFT_SUCCESS,
      DELETE_NFT_FAILURE],
    variables: {},
    dispatch,
    getState,
  });
}

export const callAPIDeleteNftMultiple = (ids) => async (dispatch, getState) => {
  return callAPI({
    method: 'delete',
    apiPath: `/nft/multiple?ids=${ids}`,
    actionTypes: [
      DELETE_NFT_MULTIPLE,
      DELETE_NFT_SUCCESS_MULTIPLE,
      DELETE_NFT_FAILURE_MULTIPLE],
    variables: {},
    dispatch,
    getState,
  });
}
