import {createReducer} from "redux-create-reducer";
import {convertToDate} from "../../../utils/helper";
import callAPI from "../../../utils/callAPI";

export const UPDATE_USER_ACTION = 'UPDATE_USER_ACTION'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURED = 'UPDATE_USER_FAILURED'
export const INVISIBLE_MODAL_ACTION = 'INVISIBLE_MODAL_ACTION'


export const CHANGE_PASSWORD_ACTION = 'CHANGE_PASSWORD_ACTION'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURED = 'CHANGE_PASSWORD_FAILURED'
export const INVISIBLE_CHANGE_PASSWORD_MODAL_ACTION = 'INVISIBLE_CHANGE_PASSWORD_MODAL_ACTION'
export const INVISIBLE_ERR_MESSAGE_ACTION = 'INVISIBLE_ERR_MESSAGE_ACTION'
export const VISIBLE_CHANGE_PASSWORD_ERROR = 'VISIBLE_CHANGE_PASSWORD_ERROR'
export const VISIBLE_CHANGE_PASSWORD_SUCCESS = 'VISIBLE_CHANGE_PASSWORD_SUCCESS'


const defaultState = {
    productFilter: {
        limit: 10,
        page: 1,
    },
    updateUserSuccess: false,
    updateUserFailured: false,
};

const reducer = createReducer(defaultState, {
    [UPDATE_USER_SUCCESS]: (state) => {
        return {
            ...state,
            updateUserSuccess: true
        }
    },
    [UPDATE_USER_FAILURED]: (state) => {
        return {
            ...state,
            updateUserFailured: true
        }
    },
    [INVISIBLE_MODAL_ACTION]: (state) => {
        return {
            ...state,
            updateUserFailured: false,
            updateUserSuccess: false
        }
    },
    [VISIBLE_CHANGE_PASSWORD_SUCCESS]: (state) => {
        return {
            ...state,
            changePasswordSuccess: true,
        }
    }, [VISIBLE_CHANGE_PASSWORD_ERROR]: (state) => {
        return {
            ...state,
            changePasswordFailured: true,
        }
    }, [CHANGE_PASSWORD_FAILURED]: (state) => {
        return {
            ...state,
            changePasswordFailured: true,
        }
    },
    [INVISIBLE_CHANGE_PASSWORD_MODAL_ACTION]: (state) => {
        return {
            ...state,
            changePasswordSuccess: false,
            changePasswordFailured: false
        }
    },
});

export default reducer;
export const namespace = "myProfile";

export const updateUserAction = (payload) => ({
    type: UPDATE_USER_ACTION,
    payload
});
export const changePasswordAction = (payload) => ({
    type: CHANGE_PASSWORD_ACTION,
    payload
});

export const invisibleChangePasswordModalAction = (payload) => ({
    type: INVISIBLE_CHANGE_PASSWORD_MODAL_ACTION
});

export const invisibleErrMessageAction = () => ({
    type: INVISIBLE_ERR_MESSAGE_ACTION
});

export const visibleChangePasswordSuccess = (payload) => ({
    type: VISIBLE_CHANGE_PASSWORD_SUCCESS
});
export const visibleChangePasswordError = (payload) => ({
    type: VISIBLE_CHANGE_PASSWORD_ERROR
});

export const invisibleModalAction = () => ({
    type: INVISIBLE_MODAL_ACTION,
});

export const updateUser = (payload) => async (dispatch, getState) => {
    return callAPI({
        method: 'put',
        apiPath: '/user/update-user',
        actionTypes: [
            UPDATE_USER,
            UPDATE_USER_SUCCESS,
            UPDATE_USER_FAILURED],
        variables: {
            fullName: payload.fullName,
            bio: payload.bio,
            dateOfBirth: convertToDate(payload.birthday)
        },
        dispatch,
        getState,
    });
}

export const changePassword = (payload) => async (dispatch, getState) => {
    console.log("payload", payload)
    return callAPI({
        method: 'put',
        apiPath: '/user/change-password',
        actionTypes: [
            CHANGE_PASSWORD,
            CHANGE_PASSWORD_SUCCESS,
            CHANGE_PASSWORD_FAILURED],
        variables: {
            password: payload.password,
            newPassword: payload.newPassword,
        },
        dispatch,
        getState,
    });
}
