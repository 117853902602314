import {all, fork, put, select, takeEvery, takeLatest} from 'redux-saga/effects'

import {
    DELETE_IMAGE_ACTION,
    deleteImage,
    FETCH_CONTENT_MANAGEMENT_ACTION,
    getContentManagement,
    SAVE_CONTENT_MANAGEMENT_ACTION,
    saveContentManagement,
    updateImage,
    UPLOAD_IMAGE_ACTION,
    UPLOAD_IMAGE_SUCCESS,
    uploadImage,
} from '.'

function* fetchContentManagementData() {
    yield takeEvery(FETCH_CONTENT_MANAGEMENT_ACTION, function* () {
        yield put(getContentManagement)
    })
}

function* uploadImageSaga() {
    let key;
    yield takeLatest(UPLOAD_IMAGE_ACTION, function* (action) {
        const {payload} = action
        key = payload.key;
        console.log("uploadImageSaga", action)
        yield put(uploadImage(payload.file));
    })

    yield takeEvery(UPLOAD_IMAGE_SUCCESS, function* (action) {
        const payload = {
            key: key,
            url: action.payload.data
        }
        yield put(updateImage(payload))
    })
}

function* deleteImageSaga() {
    yield takeLatest(DELETE_IMAGE_ACTION, function* (action) {
        const {payload} = action
        const {contentManagement} = yield select();
        console.log("contentManagement.data.", contentManagement.data)
        const record = contentManagement.data.find(item => payload.key === item.key)
            console.log("deleteImageSaga", record)

        if (record?.image) {
            console.log("haha")
            const url = record.image;
            const filename = new URL(url).pathname.split("/").pop();
            yield put(deleteImage(filename));
        }
    })
}

function* saveContentManagementSaga() {
    yield takeLatest(SAVE_CONTENT_MANAGEMENT_ACTION, function* (action) {
        const {payload} = action
        yield put(saveContentManagement(JSON.stringify(payload)));
    })
}

export default function* loadContentManagementPage() {
    yield all([
        fork(fetchContentManagementData),
        fork(uploadImageSaga),
        fork(deleteImageSaga),
        fork(saveContentManagementSaga),
    ])
}
