import {createReducer} from "redux-create-reducer";
import callAPI from "../../../utils/callAPI";

export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SAGA = "GET_TRANSACTION_SAGA";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";
export const CHANGE_FILTER_TRANSACTION = "CHANGE_FILTER_TRANSACTION";


const defaultState = {
  data: [],
  pagination: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 20,
    totalPages: 0,
    currentPage: 1
  },
  transactionFilter: {
    searchText: '',
    status: ''
  }
}

const reducer = createReducer(defaultState, {
  [GET_TRANSACTION_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data.items,
    pagination: action.payload.data.meta
  }),
  [CHANGE_FILTER_TRANSACTION]: (state, action) => ({
    ...state,
    transactionFilter: action.payload
  })
})

export const getTransactionAction = (page, limit) => ({
  type: GET_TRANSACTION_SAGA,
  payload: {page, limit},
})

export const changeFilterTransaction = (searchText, status) => ({
  type: CHANGE_FILTER_TRANSACTION,
  payload: {searchText, status},
})

export const getTransactionHistory = (page, limit, searchText, status) => async (dispatch, getState) => {
  let variables = {page, limit}
  if (!!searchText) {
    variables = {...variables, searchText}
  }

  if (!!status) {
    variables = {...variables, status}
  }

  return callAPI({
    method: "get",
    apiPath: "/transactions",
    actionTypes: [GET_TRANSACTION, GET_TRANSACTION_SUCCESS, GET_TRANSACTION_FAILURE],
    variables,
    dispatch,
    getState,
  });
};


export default reducer
export const namespace = "nftHistory";
