import {all, fork, put, takeLatest} from 'redux-saga/effects'
import {
  GET_OTP_SAGA,
  getWalletAddress,
  sendOTPSaga,
  updateWalletAddress,
  VERIFY_OTP_SAGA,
  VERIFY_OTP_SUCCESS,
  verifyOTP
} from "./index";

function* loadRouteData() {
  yield put(getWalletAddress());
}

function* sendOpt() {
  yield put(sendOTPSaga());
}

function* verifyOpt(action) {
  yield put(verifyOTP(action.payload));
}

function* updateWallet() {
  yield put(updateWalletAddress());
}

export default function* walletAddressSaga() {
    yield all([
      fork(loadRouteData),
      takeLatest(GET_OTP_SAGA, sendOpt),
      takeLatest(VERIFY_OTP_SAGA, verifyOpt),
      takeLatest(VERIFY_OTP_SUCCESS, updateWallet)
    ])
}
