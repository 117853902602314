export const ROUTE_HOME = 'route/ROUTE_HOME'
export const ROUTE_CONTENT_MANAGEMENT = 'route/ROUTE_CONTENT_MANAGEMENT'
export const ROUTE_NFT_HISTORY = 'route/ROUTE_NFT_HISTORY'
export const ROUTE_WALLET_ADDRESS = 'route/ROUTE_WALLET_ADDRESS'
export const ROUTE_VERSION_CONTROL = 'route/ROUTE_VERSION_CONTROL'
export const ROUTE_MY_PROFILE = 'route/ROUTE_MY_PROFILE'
export const ROUTE_CHANGE_PASSWORD = 'route/ROUTE_CHANGE_PASSWORD'
export const ROUTE_LOGIN = 'route/ROUTE_LOGIN'

export const selectRouteType = (state) => state.location.type
export const selectRoutesMap = (state) => state.location.routesMap
export const selectPreviousRoute = (state) => state.location.prev

export const goToPage = (routeType, payload, rest) => ({
    type: routeType,
    payload: payload,
    ...rest,
})
