import React from 'react';

import styles from './styles.module.scss'

const Loading = () => {
    return (
      <div className={styles.loader}/>
    )
}

export default Loading
