import {NOT_FOUND} from "redux-first-router";
import {
    ROUTE_CHANGE_PASSWORD,
    ROUTE_CONTENT_MANAGEMENT,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_MY_PROFILE, ROUTE_NFT_HISTORY,
    ROUTE_VERSION_CONTROL, ROUTE_WALLET_ADDRESS
} from "./state/modules/routing";
import {loadHomePage} from "./state/modules/home/saga";
import loadContentManagementPage from "./state/modules/contentManagement/sagas";
import loadVersionControl from "./state/modules/versionControl/saga";
import loadMyProfilePage from "./state/modules/myProfile/saga";
import nftHistory from "./state/modules/nftHistory/sagas";
import walletAddressSaga from "./state/modules/walletAddress/sagas";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    [NOT_FOUND]: {
        path: "/not-found",
        component: "NotFound",
    },
    [ROUTE_LOGIN]: {
        path: "/login",
        component: "Login",
        requiresAuth: false,
    },
    [ROUTE_HOME]: {
        path: "/",
        component: "Home",
        saga: loadHomePage,
        requiresAuth: true,
    },
    [ROUTE_CONTENT_MANAGEMENT]: {
        path: "/content-management",
        component: "ContentManagement",
        saga: loadContentManagementPage,
        requiresAuth: true,
    },
    [ROUTE_VERSION_CONTROL]: {
        path: "/version-control",
        component: "VersionControl",
        saga: loadVersionControl,
        requiresAuth: true,
    },
    [ROUTE_MY_PROFILE]: {
        path: "/my-profile",
        component: "MyProfile",
        saga: loadMyProfilePage,
        requiresAuth: true,
    },
    [ROUTE_CHANGE_PASSWORD]: {
        path: "/change-password",
        component: "ChangePassword",
        saga: loadMyProfilePage,
        requiresAuth: true,
    },
    [ROUTE_NFT_HISTORY]: {
        path: "/nft-history",
        component: "NftHistory",
        saga: nftHistory,
        requiresAuth: true,
    },
    [ROUTE_WALLET_ADDRESS]: {
        path: "/wallet-address",
        component: "WalletAddress",
        saga: walletAddressSaga,
        requiresAuth: true,
    },
};
