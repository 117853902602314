import {createReducer} from "redux-create-reducer";
import callAPI from "../../../utils/callAPI";

export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE";
export const GET_OTP = "GET_OTP";
export const GET_OTP_SAGA = "GET_OTP_SAGA";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SAGA = "VERIFY_OTP_SAGA";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const CHANGE_ETH_ADDRESS = "CHANGE_ETH_ADDRESS";
export const CHANGE_SOL_ADDRESS = "CHANGE_SOL_ADDRESS";
export const CHANGE_HBAR_ADDRESS = "CHANGE_HBAR_ADDRESS";
export const CHANGE_POLYGON_ADDRESS = "CHANGE_POLYGON_ADDRESS";
export const CHANGE_OTP = "CHANGE_OTP";
export const SHOW_OTP = "SHOW_OTP";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";


const defaultState = {
  data: {
    ethAddress: "",
    solAddress: "",
    hederaAddress: "",
    polygonAddress: ""
  },
  isLoading: false,
  isEdit: false,
  otp: '',
  isOpenModalOtp: false,
  isShowVerifyFail: false
}

const reducer = createReducer(defaultState, {
  [GET_ADDRESS]: (state) => ({
    ...state,
    isLoading: true,
    isEdit: false,
  }),
  [GET_ADDRESS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data.data,
    isLoading: false,
    isEdit: false,
  }),
  [GET_ADDRESS_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
    isEdit: false,
  }),
  [CHANGE_ETH_ADDRESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      ethAddress: action.payload
    },
    isEdit: true,
  }),
  [CHANGE_SOL_ADDRESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      solAddress: action.payload
    },
    isEdit: true,
  }),
  [CHANGE_HBAR_ADDRESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      hederaAddress: action.payload
    },
    isEdit: true,
  }),
  [CHANGE_POLYGON_ADDRESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      polygonAddress: action.payload
    },
    isEdit: true,
  }),
  [CHANGE_OTP]: (state, action) => ({
    ...state,
    otp: action.payload,
    isShowVerifyFail: false,
  }),
  [SHOW_OTP]: (state, action) => ({
    ...state,
    isOpenModalOtp: action.payload,
    otp: action.payload ? state.otp : ''
  }),
  [VERIFY_OTP_FAILURE]: (state, action) => ({
    ...state,
    isShowVerifyFail: true,
    otp: ''
  }),
  [UPDATE_ADDRESS_FAILURE]: (state, action) => ({
    ...state,
    isShowVerifyFail: true,
    otp: ''
  }),
  [UPDATE_ADDRESS_SUCCESS]: (state, action) => ({
    ...state,
    isOpenModalOtp: false,
    otp: ''
  }),
})


export default reducer
export const namespace = "walletAddress";


export const onChangeEth = (value) => ({
  type: CHANGE_ETH_ADDRESS,
  payload: value,
});

export const onChangeSol = (value) => ({
  type: CHANGE_SOL_ADDRESS,
  payload: value,
});

export const onChangeHbar = (value) => ({
  type: CHANGE_HBAR_ADDRESS,
  payload: value,
});

export const onChangePolygon = (value) => ({
  type: CHANGE_POLYGON_ADDRESS,
  payload: value,
});

export const onChangeOtp = (value) => ({
  type: CHANGE_OTP,
  payload: value,
});

export const onShowOtp = (value) => ({
  type: SHOW_OTP,
  payload: value,
});

export const sendOtpAction = () => ({
  type: GET_OTP_SAGA
})

export const verifyAction = (value) => ({
  type: VERIFY_OTP_SAGA,
  payload: value,
})

export const getWalletAddress = () => async (dispatch, getState) => {
  return callAPI({
    method: "get",
    apiPath: "/app-config/WALLET_ADDRESS",
    actionTypes: [GET_ADDRESS, GET_ADDRESS_SUCCESS, GET_ADDRESS_FAILURE],
    dispatch,
    getState,
  });
};

export const sendOTPSaga = () => async (dispatch, getState) => {
  return callAPI({
    method: "post",
    apiPath: "/otp/send",
    actionTypes: [GET_OTP, GET_OTP_SUCCESS, GET_OTP_FAILURE],
    variables: {
      type: "CHANGE_TREASURY_WALLET_ADDRESS"
    },
    dispatch,
    getState,
  });
};

export const verifyOTP = (code) => async (dispatch, getState) => {
  return callAPI({
    method: "post",
    apiPath: "/otp/verify",
    actionTypes: [VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILURE],
    variables: {
      type: "CHANGE_TREASURY_WALLET_ADDRESS",
      code
    },
    dispatch,
    getState,
  });
};

export const updateWalletAddress = () => async (dispatch, getState) => {
  const state = await getState()
  const walletAddress = state.walletAddress
  return callAPI({
    method: "put",
    apiPath: "/app-config",
    actionTypes: [UPDATE_ADDRESS, UPDATE_ADDRESS_SUCCESS, UPDATE_ADDRESS_FAILURE],
    variables: {
      type: "WALLET_ADDRESS",
      data: walletAddress.data,
      otpCode: walletAddress.otp
    },
    dispatch,
    getState,
  });
};
