import {all, fork, put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {CHANGE_FILTER_TRANSACTION, changeFilterTransaction, GET_TRANSACTION_SAGA, getTransactionHistory} from "./index";

function* initData() {
  yield put(changeFilterTransaction('', ''))
  yield put(getTransactionHistory(1, 20));
}

function* handleListenActions() {
  yield takeEvery([CHANGE_FILTER_TRANSACTION], function* (action) {
    const {nftHistory} = yield select();
    const {transactionFilter} = nftHistory;
    yield put(getTransactionHistory(1, 20, transactionFilter.searchText, transactionFilter.status));
  });
}

function* loadMore(action) {
  const {page, limit} = action.payload
  const {nftHistory} = yield select();
  const {transactionFilter} = nftHistory;
  yield put(getTransactionHistory(page, limit, transactionFilter.searchText, transactionFilter.status));
}

export default function* nftHistory() {
    yield all([
      fork(initData),
      fork(handleListenActions),
      takeLatest(GET_TRANSACTION_SAGA, loadMore)
    ])
}
