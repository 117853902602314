import React from 'react';

const RouteErrorScreen = () => {

    return (
        <div>ERRRR</div>
    )
}

export default RouteErrorScreen
