import {all, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {
    CHANGE_PASSWORD_ACTION,
    CHANGE_PASSWORD_SUCCESS,
    changePassword,
    UPDATE_USER_ACTION,
    UPDATE_USER_SUCCESS,
    updateUser,
    visibleChangePasswordError,
    visibleChangePasswordSuccess
} from "./index";
import {fetchUserInfor} from "../auth";

function* updateUserSaga() {
    yield takeEvery(UPDATE_USER_ACTION, function* (action) {
        const {payload} = action;
        console.log("payload", payload)
        yield put(updateUser(payload))
    })
    yield takeEvery(UPDATE_USER_SUCCESS, function* () {
        yield put(fetchUserInfor())
    })
}

function* changePasswordSaga() {
    yield takeLatest(CHANGE_PASSWORD_ACTION, function* (action) {
        const {payload} = action;
        yield put(changePassword(payload))
    })
    yield takeEvery(CHANGE_PASSWORD_SUCCESS, function* (action) {
        if (action.payload.statusCode === 200) {
            yield put(visibleChangePasswordSuccess())
        } else {
            yield put(visibleChangePasswordError())
        }

    })
}


export default function* loadMyProfilePage() {
    yield all([
        fork(updateUserSaga),
        fork(changePasswordSaga),
    ])
}