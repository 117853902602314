import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from './state/configureStore';

import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react';
// import { Provider } from 'react-redux';
// import { render } from 'react-dom';

// // import validateAppConfig from '~helpers/validateAppConfig';
// import configureStore from './state/configureStore';
// import './index.css';

// import App from './pages/App';

// // validateAppConfig();

// function bootstrap() {
//   const store = configureStore();
//   render(
//       <Provider store={store}>
//         <App />
//       </Provider>,
//     document.getElementById('root')
//   );
// }

// bootstrap();
