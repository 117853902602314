import _ from 'lodash';
import moment from "moment";
import dayjs from "dayjs";

export const isEmptyAllValue = (obj) => {
    for (let key in obj) {
        if (obj[key] !== null && obj[key] !== "")
            return false;
    }
    return true;
}


export const arrayValueToString = (array) => {
    let result = ''

    _.forEach(array, (value, index) => {
        if ((index + 1) !== array.length) {
            result += `${value},`
        } else {
            result += value
        }
    })

    return result
}

export const convertToDate = (date) => {
    if (date) {
        return dayjs(date).format('MM/DD/YYYY')
    }
    return ""
}

export const formatTransactionName = function (string) {
  if (!string) {
    return string
  }
  if (string.length <= 10) {
    return string
  }
  return string.slice(0, 6) + '...' + string.slice(string.length - 4)
}
