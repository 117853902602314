import {createReducer} from 'redux-create-reducer'
import callAPI from '../../../utils/callAPI'

export const FETCH_CONTENT_MANAGEMENT_ACTION = 'FETCH_CONTENT_MANAGEMENT_ACTION'
export const FETCH_CONTENT_MANAGEMENT = 'FETCH_CONTENT_MANAGEMENT'
export const FETCH_CONTENT_MANAGEMENT_SUCCESS = 'FETCH_CONTENT_MANAGEMENT_SUCCESS'
export const FETCH_CONTENT_MANAGEMENT_FAILURED = 'FETCH_CONTENT_MANAGEMENT_FAILURED'

export const UPLOAD_IMAGE_ACTION = 'UPLOAD_IMAGE_ACTION'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURED = 'UPLOAD_IMAGE_FAILURED'

export const DELETE_IMAGE_ACTION = 'DELETE_IMAGE_ACTION'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAILURED = 'DELETE_IMAGE_FAILURED'

export const SAVE_CONTENT_MANAGEMENT_ACTION = 'SAVE_CONTENT_MANAGEMENT_ACTION'
export const SAVE_CONTENT_MANAGEMENT = 'SAVE_CONTENT_MANAGEMENT'
export const SAVE_CONTENT_MANAGEMENT_SUCCESS = 'SAVE_CONTENT_MANAGEMENT_SUCCESS'
export const SAVE_CONTENT_MANAGEMENT_FAILURED = 'SAVE_CONTENT_MANAGEMENT_FAILURED'

export const UPDATE_IMAGE = 'UPDATE_IMAGE'

export const ADD_RECORD_CONTENT_MANAGEMENT_ACTION = 'ADD_RECORD_CONTENT_MANAGEMENT_ACTION'
export const CHANGE_CONTENT_MANAGEMENT_ACTION = 'CHANGE_CONTENT_MANAGEMENT_ACTION'

// export const FETCH_CONTENT_MANAGEMENT = 'FETCH_CONTENT_MANAGEMENT'
// export const FETCH_CONTENT_MANAGEMENT_SUCCESS = 'FETCH_CONTENT_MANAGEMENT_SUCCESS'
// export const FETCH_CONTENT_MANAGEMENT_FAILURED = 'FETCH_CONTENT_MANAGEMENT_FAILURED'

const defaultState = {
    data: []
}

const reducer = createReducer(defaultState, {
    [FETCH_CONTENT_MANAGEMENT_SUCCESS]: (state, action) => {
        return {
            ...state,
            data: JSON.parse(action.payload.data.data)
        }
    },
    [FETCH_CONTENT_MANAGEMENT_FAILURED]: (state, action) => {
        return {
            ...state
        }
    },
    [UPLOAD_IMAGE_SUCCESS]: (state, action) => {
        return {
            ...state
        }
    },
    [UPDATE_IMAGE]: (state, action) => {
        const newData = state.data.map(item => {
            if (action.payload.key === item.key) {
                return {
                    ...item,
                    image: process.env.REACT_APP_BASE_URL + action.payload.url
                }
            }
            return item
        })
        return {
            ...state,
            data: newData
        }
    },
    [ADD_RECORD_CONTENT_MANAGEMENT_ACTION]: (state, action) => {
        const newData = action.payload.data;
        return {
            ...state,
            data: newData
        }
    },
    [CHANGE_CONTENT_MANAGEMENT_ACTION]: (state, action) => {
        const newData = action.payload;
        return {
            ...state,
            data: newData
        }
    }
})

export const fetchDataRequest = () => ({
    type: FETCH_CONTENT_MANAGEMENT_ACTION
});
export const uploadImageAction = (payload) => ({
    type: UPLOAD_IMAGE_ACTION,
    payload,
});
export const deleteImageAction = (payload) => ({
    type: DELETE_IMAGE_ACTION,
    payload,
});

export const updateImage = (payload) => ({
    type: UPDATE_IMAGE,
    payload,
});

export const saveContentManagementAction = (payload) => ({
    type: SAVE_CONTENT_MANAGEMENT_ACTION,
    payload,
});


export const addContentManagementAction = (payload) => ({
    type: ADD_RECORD_CONTENT_MANAGEMENT_ACTION,
    payload,
});

export const changeContentManagementAction = (payload) => ({
    type: CHANGE_CONTENT_MANAGEMENT_ACTION,
    payload,
});


export default reducer
export const namespace = "contentManagement";
export const getContentManagement = async (dispatch, getState) => {
    return callAPI({
        method: 'get',
        apiPath: '/content-config/lastest',
        actionTypes: [
            FETCH_CONTENT_MANAGEMENT,
            FETCH_CONTENT_MANAGEMENT_SUCCESS,
            FETCH_CONTENT_MANAGEMENT_FAILURED],
        variables: {
            page: 1,
            limit: 10
        },
        dispatch,
        getState,
    });
}
export const saveContentManagement = (payload) => async (dispatch, getState) => {
    return callAPI({
        method: 'post',
        apiPath: '/content-config/save',
        actionTypes: [
            SAVE_CONTENT_MANAGEMENT,
            SAVE_CONTENT_MANAGEMENT_SUCCESS,
            SAVE_CONTENT_MANAGEMENT_FAILURED],
        variables: {
            data: payload
        },
        dispatch,
        getState,
    });
}

export const uploadImage = (data) => async (dispatch, getState) => {
    let formData = new FormData()
    formData.append('file', data)
    return callAPI({
        method: 'post',
        apiPath: '/file/upload-files',
        actionTypes: [
            UPLOAD_IMAGE,
            UPLOAD_IMAGE_SUCCESS,
            UPLOAD_IMAGE_FAILURED],
        variables: formData,
        dispatch,
        getState,
        headers: {'Content-Type': 'multipart/form-data'},
    });
}


export const deleteImage = (data) => async (dispatch, getState) => {
    console.log(data)
    const apiPath = `/file/${data}`
    return callAPI({
        method: 'delete',
        apiPath: apiPath,
        actionTypes: [
            DELETE_IMAGE,
            DELETE_IMAGE_SUCCESS,
            DELETE_IMAGE_FAILURED],
        variables: {},
        dispatch,
        getState,
    });
}
