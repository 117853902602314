import {createReducer} from "redux-create-reducer";
import callAPI from "../../../utils/callAPI";
import {convertToDate} from "../../../utils/helper";

export const FETCH_VERSION_CONTROL = 'FETCH_VERSION_CONTROL'
export const FETCH_VERSION_CONTROL_ACTION = 'FETCH_VERSION_CONTROL_ACTION'
export const FETCH_VERSION_CONTROL_SUCCESS = 'FETCH_VERSION_CONTROL_SUCCESS'
export const FETCH_VERSION_CONTROL_FAILURED = 'FETCH_VERSION_CONTROL_FAILURED'

export const APPLY_VERSION_CONTROL = 'APPLY_VERSION_CONTROL'
export const APPLY_VERSION_CONTROL_ACTION = 'APPLY_VERSION_CONTROL_ACTION'
export const APPLY_VERSION_CONTROL_SUCCESS = 'APPLY_VERSION_CONTROL_SUCCESS'
export const APPLY_VERSION_CONTROL_FAILURED = 'APPLY_VERSION_CONTROL_FAILURED'
export const CHANGE_FILTER = "CHANGE_FILTER";

const defaultState = {
    versionControlFilter: {
        limit: 10,
        page: 1,
    },

    data: [
        {
            key: 1,
            version: '1.0',
            dateCreated: '13/04/2022',
            lastApplied: '19/02/2021'
        },
        {
            key: 2,
            version: '2.0',
            dateCreated: '13/04/2022',
            lastApplied: '19/02/2021'
        },
        {
            key: 3,
            version: '3.0',
            dateCreated: '13/04/2022',
            lastApplied: '19/02/2021'
        },
    ],
    fetchingData: false,
    failedFetchingData: false,
    totalVersionControl: 0

};

export const fetchVersionControl = () => ({
    type: FETCH_VERSION_CONTROL_ACTION
});

export const applyVersionControlAction = (payload) => ({
    type: APPLY_VERSION_CONTROL_ACTION,
    payload
});

export const changeFilter = (payload) => ({
    type: CHANGE_FILTER,
    payload,
});

const reducer = createReducer(defaultState, {
    [FETCH_VERSION_CONTROL_SUCCESS]: (state, action) => {
        const dataMap = action.payload.data.contentConfig.map(item => {
            return {
                key: item.id,
                version: item.id,
                dateCreated: convertToDate(item.createdAt),
                lastApplied: convertToDate(item.updatedAt),
                status: item.status
            }
        })
        return {
            ...state,
            data: dataMap,
            totalVersionControl: action.payload?.data.total,
        }
    },
    [CHANGE_FILTER]: (state, action) => ({
        ...state,
        versionControlFilter: action.payload,
    }),
});

export default reducer;
export const namespace = "versionControl";

export const getVersionControl =
    ({page, limit}) => async (dispatch, getState) => {
        console.log("page", page)
        console.log("limit", limit)
        return callAPI({
            method: 'get',
            apiPath: '/content-config/list',
            actionTypes: [
                FETCH_VERSION_CONTROL,
                FETCH_VERSION_CONTROL_SUCCESS,
                FETCH_VERSION_CONTROL_FAILURED
            ],
            variables: {page, limit},
            dispatch,
            getState,
        });
    }

export const applyVersionControlAPI = (id) => async (dispatch, getState) => {
    return callAPI({
        method: 'put',
        apiPath: '/content-config/active',
        actionTypes: [
            APPLY_VERSION_CONTROL,
            APPLY_VERSION_CONTROL_SUCCESS,
            APPLY_VERSION_CONTROL_FAILURED
        ],
        variables: {
            id: id
        },
        dispatch,
        getState,
    });
}
