import {all, fork, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {
  callAPIDeleteNFT,
  callAPIDeleteNftMultiple,
  CHANGE_FILTER,
  DELETE_NFT_MULTIPLE_SAGA,
  DELETE_NFT_SAGA, DELETE_NFT_SUCCESS, DELETE_NFT_SUCCESS_MULTIPLE,
  getNFTs,
  IMPORT_NFTS_SUCCESS,
} from "./index";

function* loadRouteData() {
    const {home} = yield select();
    const {nftFilter} = home;
    yield put(getNFTs(nftFilter));
}

function* handleListenActions() {
    yield takeEvery([CHANGE_FILTER, IMPORT_NFTS_SUCCESS], function* (action) {
        const {home} = yield select();
        const {nftFilter} = home;
        yield put(getNFTs(nftFilter));
    });
}

function* deleteNftMultipleSaga(action) {
  yield put(callAPIDeleteNftMultiple(action.payload));
}

function* deleteNftSingerSaga(action) {
  yield put(callAPIDeleteNFT(action.payload));
}

export function* loadHomePage() {
    yield all([
      fork(loadRouteData),
      fork(handleListenActions),
      takeLatest(DELETE_NFT_MULTIPLE_SAGA, deleteNftMultipleSaga),
      takeLatest(DELETE_NFT_SAGA, deleteNftSingerSaga),
      takeLatest(DELETE_NFT_SUCCESS, loadRouteData),
      takeLatest(DELETE_NFT_SUCCESS_MULTIPLE, loadRouteData)
    ]);
}
