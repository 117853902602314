import {all, fork, put, select, takeLatest} from 'redux-saga/effects'
import {redirect} from 'redux-first-router'
import {get} from 'lodash'

import {
    FETCH_USER_FAILURED,
    FETCH_USER_SUCCESS,
    fetchUserInfor,
    handleLogin,
    LOGIN_ACTION,
    LOGOUT_ACTION,
    REQUEST_LOGIN_SUCCESSED,
} from '.'
import {bootFinished} from '../app'
import {ROUTE_HOME, ROUTE_LOGIN} from '../routing'
import {removeAuthToken, removeUserInfo, setAuthToken} from '../../../utils/localStorage'

function* redirectAwayAfterUserLogin() {
    yield takeLatest(FETCH_USER_SUCCESS, function* () {
        const {location} = yield select()
        let redirectType = get(location, 'type', '')
        const payload = get(location, 'payload', '')
        const query = get(location, 'query', '')
        yield put(bootFinished())

        // Allow to redirect
        yield put(
            redirect({
                type: redirectType && redirectType !== ROUTE_LOGIN ? redirectType : ROUTE_HOME,
                payload,
                query,
            }),
        )
    })

    yield takeLatest(REQUEST_LOGIN_SUCCESSED, function* (action) {
        setAuthToken(action.payload.data.accessToken)
        yield put(fetchUserInfor(action.payload.data.accessToken))
    })

    yield takeLatest(FETCH_USER_FAILURED, function* () {
        const {location} = yield select()
        let redirectType = get(location, 'type', '')
        const payload = get(location, 'payload', '')
        const query = get(location, 'query', '')

        yield put(bootFinished())
        yield put(
            redirect({
                type: redirectType && redirectType !== ROUTE_LOGIN ? ROUTE_LOGIN : redirectType,
                payload,
                query,
            }),
        )
    })
}

function* logout() {
    const {location} = yield select()
    removeAuthToken();
    removeUserInfo();
    // eslint-disable-next-line no-undef
    if (process.env.REACT_APP_ENV === 'local') {
        yield put(
            redirect({
                type: ROUTE_LOGIN,
                query: location.type !== ROUTE_LOGIN ? {redirect: location.type} : {},
            }),
        )
    } else {
        window.location.pathname = '/login'
    }
}

function* login() {
    yield takeLatest(LOGIN_ACTION, function* (action) {
        const {payload} = action
        yield put(handleLogin(payload.email, payload.password))
    })
}

export default function* auth() {
    yield all([
        takeLatest(LOGOUT_ACTION, logout),
        fork(redirectAwayAfterUserLogin),
        fork(login),
    ])
}
