import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'

import appReducer, {namespace as appNamespace} from './modules/app'
import authReducer, {namespace as authNamespace} from './modules/auth'
import homeReducer, {namespace as homeNamespace} from './modules/home'
import contentManagementReducer, {namespace as contentManagementNamespace} from './modules/contentManagement'
import versionControlReducer, {namespace as versionControlNamespace} from './modules/versionControl'
import myProfileReducer, {namespace as myProfileNamespace} from './modules/myProfile'
import nftHistoryReducer, {namespace as nftHistoryNamespace} from './modules/nftHistory'
import walletAddressReducer, {namespace as walletAddressNamespace} from './modules/walletAddress'

const RootReducer = (extraReducers) =>
    combineReducers({
        [authNamespace]: authReducer,
        [appNamespace]: appReducer,
        form: formReducer,
        [homeNamespace]: homeReducer,
        [contentManagementNamespace]: contentManagementReducer,
        [versionControlNamespace]: versionControlReducer,
        [myProfileNamespace]: myProfileReducer,
        [nftHistoryNamespace]: nftHistoryReducer,
        [walletAddressNamespace]: walletAddressReducer,
        ...extraReducers,
    })
export default RootReducer
