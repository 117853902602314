import {all, fork, put, select, takeLatest} from 'redux-saga/effects'
import {
    APPLY_VERSION_CONTROL_ACTION,
    APPLY_VERSION_CONTROL_SUCCESS,
    applyVersionControlAPI,
    CHANGE_FILTER,
    FETCH_VERSION_CONTROL_ACTION,
    getVersionControl
} from "./index";

function* fetchVersionControlData() {
    yield takeLatest([FETCH_VERSION_CONTROL_ACTION, APPLY_VERSION_CONTROL_SUCCESS, CHANGE_FILTER], function* () {
        const {versionControl} = yield select();
        const {versionControlFilter} = versionControl;
        yield put(getVersionControl(versionControlFilter));
    })
}

function* applyVersionControlSaga() {
    yield takeLatest(APPLY_VERSION_CONTROL_ACTION, function* (action) {
        const {payload} = action;
        console.log(payload)
        yield put(applyVersionControlAPI(payload.id));
    })
}


export default function* loadVersionControl() {
    yield all([
        fork(fetchVersionControlData),
        fork(applyVersionControlSaga),
    ])
}
